import { useEffect } from "react";
import { useNavigate } from "react-router";
import clsx from "clsx";

import Button from "@/components/common/input/button.tsx";
import Input from "@/components/common/input/input.tsx";
// import "../../scss/_login.scss";
import FullPageSpinner from "@/components/common/full-page-spinner.tsx";
import { useAuth } from "@/hooks/auth.tsx";
import google_logo from "@/img/google-square.svg";
import msft_logo from "@/img/microsoft-square.svg";

export default function LoginPage() {
  const { user, error, is_loading, signIn } = useAuth();
  const navigate = useNavigate();

  const _handleSubmit = (e) => {
    e.preventDefault();
    const usr = e.target.elements.username.value;
    const pwd = e.target.elements.password.value;
    if (usr !== "" && pwd !== "") {
      signIn(usr, pwd);
    }
  };

  useEffect(() => {
    if (user) {
      const url = new URL(window.location.href);
      // do we have a to param?
      const to_param = url.searchParams.get("to");
      navigate(to_param ? to_param : "/", { replace: true });
    }
  }, [user, navigate]);

  if (user || is_loading) {
    return <FullPageSpinner />;
  }

  const extauthclass = clsx(
    "flex w-60 cursor-pointer items-center gap-4 rounded-md border-2 px-4 py-2",
    "transition-btn",
    "duration-150",
    "ease-out",
    "hover:ring-4",
    "hover:ring-offset-0",
    "hover:ring-[hsl(210deg_40%_10%_/_0.25)]",
  );

  return (
    <div className="flex h-screen w-screen items-center justify-center gap-16">
      <div className="w-[26rem]">
        <img
          src="https://storage.googleapis.com/tacolab-dev-customer-resources/assets/logo/Unfold_logo_white.png"
          alt="Unfold logotype"
        />
        <div className="mt-8 text-center font-outfit text-slate-700 uppercase tracking-[2rem]">
          Platform
        </div>
      </div>
      <div
        className={clsx(
          "w-[26rem] rounded-md border-1 border-slate-700 p-8",
          "shadow-[12px_12px_0_8px_hsl(210deg40%_2%_/_0.7)]",
        )}
      >
        <div className="flex flex-col items-center">
          <div className="my-2">
            <a className={extauthclass} href="/auth/google/login">
              <img className="w-6" src={google_logo} alt="Google logotype" />{" "}
              Sign in with Google
            </a>
          </div>
          <div className="my-2">
            <a className={extauthclass} href="/auth/microsoft/login">
              <img className="w-6" src={msft_logo} alt="Microsoft logotype" />{" "}
              Sign in with Microsoft
            </a>
          </div>
          <div className="my-8 flex items-center gap-8">
            <div className="h-0.5 w-4 bg-white" />
            <div>or</div>
            <div className="h-0.5 w-4 bg-white" />
          </div>
        </div>
        <form className="form grid grid-cols-1 gap-2" onSubmit={_handleSubmit}>
          <Input label="Email address" type="email" id="username" />
          <Input label="Password" type="password" id="password" />
          {error && (
            <div className="mb-4 rounded border border-red-500 bg-red-200 p-4 text-red-600">
              <p>{error}</p>
            </div>
          )}
          <div className="">
            <Button wide type="submit" is_loading={is_loading}>
              Sign in
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
