import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";

import Button from "@/components/common/input/button";
import Spinner from "@/components/common/spinner";
import { useAuth } from "@/hooks/auth";
import useRecaptcha from "@/hooks/recaptcha";

const TokenLogin = () => {
  const { user, tokenLogin, error, signOut } = useAuth();
  const { token } = useParams();
  const navigate = useNavigate();
  const { hide: reHide, verify: reVerify, loading: reLoading } = useRecaptcha();

  useEffect(() => {
    const abort_controller = new AbortController();
    signOut(abort_controller);
    return () => {
      abort_controller.abort();
      reHide();
    };
  }, []);

  useEffect(() => {
    if (user && user.roles.includes("guest")) {
      const url = new URL(window.location.href);
      // do we have a to param?
      const to_param = url.searchParams.get("to");
      reHide();
      navigate(to_param ? to_param : "/share", { replace: true });
    }
  }, [user]);

  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center gap-8">
      {reLoading ? (
        <Spinner large />
      ) : (
        <>
          <div className="w-[26rem] font-outfit text-[4.2rem] font-medium leading-snug">
            <img src="https://storage.googleapis.com/tacolab-dev-customer-resources/assets/logo/Unfold_logo_white.png" />
          </div>
          <Button
            onClick={async () =>
              reVerify((result) => tokenLogin(token, result))
            }
          >
            Enter
          </Button>
        </>
      )}
      {error && <div>Something went wrong. Check the link and try again.</div>}
    </div>
  );
};

export default TokenLogin;
