import React, { useState } from "react";
import {
  Await,
  useAsyncValue,
  useLoaderData,
  useRevalidator,
} from "react-router";
import clsx from "clsx";

import ApiErrorComponent from "@/components/common/api-error";
import FullPageSpinner from "@/components/common/full-page-spinner";
import { request } from "@/hooks/request";

export async function loader({ request: rr_request }) {
  const external_assignments = request("/api/external-assignment", {
    signal: rr_request.signal.signal,
  });
  const assignments = request("/api/assignment", {
    signal: rr_request.signal.signal,
  });
  return {
    external_assignments: external_assignments.then((res) => res.json),
    assignments: assignments.then((res) => res.json),
  };
}

export default function AssignmentImport() {
  const data = useLoaderData();
  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      <Await
        resolve={Promise.all([
          data.external_assignments,
          data.assignments,
        ]).then((values) => values)}
        errorElement={<ApiErrorComponent />}
      >
        <AssignmentImportDeferred />
      </Await>
    </React.Suspense>
  );
}

function AssignmentImportDeferred() {
  const [external_assignments, assignments] = useAsyncValue();
  const imported = assignments.map((assignment) => assignment.external_id);

  return (
    <div className="mx-auto max-w-[60rem]">
      <div className="flex flex-col gap-2">
        {external_assignments.map((assignment) => {
          return (
            <ImportableExternalAssignment
              key={assignment.id}
              assignment={assignment}
              imported={imported.includes(String(assignment.id))}
            />
          );
        })}
      </div>
    </div>
  );
}

function ImportableExternalAssignment({ assignment, imported }) {
  const revalidator = useRevalidator();
  const [is_submitting, setSubmitting] = useState(false);

  async function _import() {
    setSubmitting(true);
    const response = await request(
      "/api/assignment/import",
      {
        method: "post",
        body: JSON.stringify(assignment),
      },
      true,
    );
    const { error } = response.json;
    if (error) {
      console.error(error);
    }
    revalidator.revalidate();
    setSubmitting(false);
  }

  const iconclass = clsx(
    "material-symbols-rounded notranslate pr-2 cursor-pointer",
    imported && "text-green-500",
    !imported && "text-slate-400",
  );

  if (is_submitting) {
    return (
      <div className="text-slate-400">
        Synchronizing assignment and candidates...
      </div>
    );
  }

  return (
    <div className="flex items-center">
      <span onClick={_import} className={iconclass}>
        cloud_sync
      </span>
      {assignment.name} - {assignment.organization}
    </div>
  );
}
