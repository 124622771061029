import { useLoaderData, useRevalidator } from "react-router";

import Button from "@/components/common/input/button";
import Modal from "@/components/common/modal";
import CompetencyForm from "@/components/forms/competency";
import { request } from "@/hooks/request";

export async function loader() {
  const response = await request("/api/competency");
  if (!response.is_ok) {
    return { error: response.json, data: null };
  } else {
    return { data: response.json, error: null };
  }
}

export default function Competencies() {
  const { data: competencies } = useLoaderData();
  const revalidator = useRevalidator();
  function reloadCompetencies() {
    revalidator.revalidate();
  }
  return (
    <div className="mx-auto max-w-[60rem]">
      <div className="mb-4 flex items-center justify-between">
        <h2 className="font-medium text-2xl">Competencies</h2>
        <Modal>
          <Button>Add competency</Button>
          <CompetencyForm callbackFn={reloadCompetencies} />
        </Modal>
      </div>
      <div className="grid grid-cols-2">
        <div>
          <h3 className="mb-4 text-xl">Unfold skills/competencies</h3>
          {competencies
            .filter((comp) => !comp.is_evali)
            .map((comp) => {
              return (
                <CompetencyRow
                  key={comp.id}
                  competency={comp}
                  callbackFn={reloadCompetencies}
                />
              );
            })}
        </div>
        <div>
          <h3 className="mb-4 text-xl">evali competencies</h3>
          {competencies
            .filter((comp) => comp.is_evali)
            .map((comp) => {
              return (
                <CompetencyRow
                  key={comp.id}
                  competency={comp}
                  callbackFn={reloadCompetencies}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
}

function CompetencyRow({ competency, callbackFn }) {
  return (
    <div className="flex items-center gap-4">
      {competency.name}{" "}
      {!competency.is_evali && (
        <Modal>
          <div className="flex items-center text-slate-400 text-sm hover:text-slate-200">
            <span className="material-symbols-rounded notranslate pr-1">
              edit
            </span>
            Edit competency
          </div>
          <CompetencyForm id={competency.id} callbackFn={callbackFn} />
        </Modal>
      )}
    </div>
  );
}
