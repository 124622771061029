import { useEffect, useRef } from "react";
import { useRevalidator } from "react-router";

import CandidateCommentPostBox from "@/components/common/candidate-comment-post-box";
import Comment from "@/components/common/comment";
import CommentApi from "@/api/comment-api";
import { boxclass, scrollToBottom, cn } from "@/helpers";

export default function CommentSection({ candidate, comments }) {
  const revalidator = useRevalidator();
  const ref = useRef(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (window.location.hash === "#add-comment") {
      scrollToBottom(ref);
    }
  }, [window.location.hash]);

  const _postCallback = () => {
    revalidator.revalidate();
    setTimeout(() => {
      scrollToBottom(ref);
    }, 100);
  };

  const _deleteComment = (comment_id) => {
    const abort_controller = new AbortController();
    CommentApi.deleteCandidateComment(comment_id, abort_controller.signal).then(
      ({ error }) => {
        if (error) {
          console.error(error);
        } else {
          revalidator.revalidate();
        }
      },
    );
  };

  return (
    <div
      className={cn("mx-auto mb-8 max-w-[40rem] md:p-8", boxclass)}
      ref={ref}
    >
      {comments.map((c) => (
        <Comment key={c.id} comment={c} deleteFn={_deleteComment} />
      ))}
      <CandidateCommentPostBox
        candidate={candidate}
        postCallback={_postCallback}
      />
    </div>
  );
}
