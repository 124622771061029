import { useLoaderData, type LoaderFunctionArgs } from "react-router";

import ApiErrorComponent from "@/components/common/api-error";
import AssessmentApi from "@/api/assessment-api";
import CandidateApi from "@/api/candidate-api";
import CandidateInfo from "@/components/candidate-info";
import CommentApi from "@/api/comment-api";
import CommentSection from "@/components/comment-section";
import ScoreCard from "@/components/scorecard";
import { usePageTitle } from "@/hooks/title";
import type { Assignment } from "@/types/assignment";
import type { Candidate } from "@/types/candidate";
import type { Scorecard } from "@/types/scorecard";

export default function CandidatePage() {
  const {
    candidate,
    assignment,
    comments,
    assessment,
    error: { c_error, co_error, sc_error },
  } = useLoaderData() as LoaderData;

  if (c_error || co_error || sc_error) {
    return <ApiErrorComponent error={c_error || co_error || sc_error} />;
  }
  usePageTitle(candidate.name);

  const hide_comments =
    assignment.user_role === "assessor" &&
    !assignment.settings?.sharing_settings?.comments;

  return (
    <div key={candidate.id} className="mx-auto max-w-[60rem]">
      <CandidateInfo full candidate={candidate} assessment={assessment} />
      {!Object.keys(assessment).includes("detail") && (
        <ScoreCard
          assessment={assessment}
          competencies={assessment.competencies}
        />
      )}
      {!hide_comments && (
        <CommentSection comments={comments} candidate={candidate} />
      )}
    </div>
  );
}

type LoaderData = {
  candidate: Candidate;
  assignment: Assignment;
  comments: any;
  assessment: Scorecard & {
    all_competencies_count: number;
    user_competencies_count: number;
  };
  error?: any;
};

export async function loader({
  request,
  params: { assignment_id, candidate_id },
}: LoaderFunctionArgs): Promise<LoaderData> {
  if (!candidate_id || !assignment_id) {
    throw new Error("Missing candidate_id or assignment_id");
  }
  const [
    {
      data: { candidate, assignment },
      error: c_error,
    },
    { data: comments, error: co_error },
    { data: assessment, error: sc_error },
  ] = await Promise.all([
    CandidateApi.fetchOne(candidate_id, assignment_id, request.signal),
    CommentApi.fetchCandidateComments(candidate_id, request.signal),
    AssessmentApi.getCandidateScorecards(candidate_id, request.signal),
  ]);
  return {
    candidate,
    comments,
    assignment,
    assessment,
    error: { c_error, co_error, sc_error },
  };
}
