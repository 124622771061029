import { createContext, useState, useContext, useEffect } from "react";

import { AUTH_COOKIE_NAME, taco_cookie_ctx } from "../auth-cookie";
import { useRequest } from "./request";
import ApiError from "@/models/ApiError";
import type { User } from "@/types/user";

interface AuthContextType {
  user: User | null;
  error: boolean | string;
  is_loading: boolean;
  setLoading: (loading: boolean) => void;
  signIn: (username: string, password: string) => Promise<void>;
  signOut: (abortController: AbortController) => Promise<void>;
  tokenLogin: (token: string, recaptcha_token: string) => Promise<void>;
  updateUser: (
    name: string,
    title: string,
    photo: File | null,
    clear_image: boolean,
    new_password?: string,
  ) => Promise<boolean>;
  updateUserPassword: (
    new_password: string,
    current_password: string,
  ) => Promise<any>;
  getCreateUserLink: (user_email: string) => Promise<{
    data: any | null;
    error: ApiError | null;
  }>;
  createUserTokenValidation: (
    token: string,
    recaptcha_token: string,
  ) => Promise<void>;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(false);
  const [is_loading, setLoading] = useState(true);
  const abort_controller = new AbortController();
  const request = useRequest({ abort_controller });

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const jwt = taco_cookie_ctx.get(AUTH_COOKIE_NAME);
    if (!user && jwt) {
      _getSession();
    } else {
      // no requests will happen, let the user through
      setLoading(false);
    }
    return () => {
      abort_controller.abort();
    };
  }, []);

  const _getSession = async () => {
    setLoading(true);
    const { json, is_ok } = await request("/api/auth/session");
    if (is_ok) {
      setUser(json.user);
    } else {
      setUser(null);
    }
    setLoading(false);
  };

  const signIn = async (username, password) => {
    setLoading(true);
    const response = await fetch("/api/auth/login", {
      method: "post",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ username, password }),
    });

    try {
      const json = await response.json();
      if (response.ok) {
        // reset error
        setError(false);
        // write jwt to cookie
        taco_cookie_ctx.set(AUTH_COOKIE_NAME, json.token);
        // and set userdata as state
        setUser(json.user);
      } else {
        setError(json.detail);
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const getCreateUserLink = (user_email) => {
    return request(`/api/auth/create-user-link/${user_email}`).then(
      (response) => {
        if (response.is_ok) {
          return { data: response.json, error: null };
        } else {
          return { data: null, error: new ApiError(response) };
        }
      },
    );
  };

  const tokenLogin = async (token, recaptcha_token) => {
    setLoading(true);
    const response = await fetch("/api/auth/token-login", {
      method: "post",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ token, recaptcha_token }),
    });
    try {
      const json = await response.json();
      if (response.ok) {
        // reset error
        setError(false);
        // write jwt to cookie
        taco_cookie_ctx.set(AUTH_COOKIE_NAME, json.token);
        // and set userdata as state
        setUser(json.user);
      } else {
        setError(json.detail);
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const createUserTokenValidation = async (token, recaptcha_token) => {
    setLoading(true);
    const response = await fetch("/api/auth/create-user-token-validation", {
      method: "post",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ token, recaptcha_token }),
    });
    try {
      const json = await response.json();
      if (response.ok) {
        // reset error
        setError(false);
        // write jwt to cookie
        taco_cookie_ctx.set(AUTH_COOKIE_NAME, json.token);
        // and set userdata as state
        setUser(json.user);
      } else {
        setError(json.detail);
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const signOut = async (abort_controller: AbortController): Promise<void> => {
    taco_cookie_ctx.remove(AUTH_COOKIE_NAME);
    if (abort_controller && !abort_controller.signal.aborted) {
      setUser(null);
    }
  };

  const updateUserPassword = async (new_password, current_password) => {
    const response = await request("/api/auth/user/pwd", {
      method: "put",
      body: JSON.stringify({ new_password, current_password }),
    });
    try {
      if (response.is_ok) {
        setError(false);
        taco_cookie_ctx.set(AUTH_COOKIE_NAME, response.json.token);
        setUser(response.json.user);
      } else {
        setError(response.json.detail);
      }
      return response;
    } catch (err) {
      console.error(err);
      setError(err);
      return response;
    }
  };

  const updateUser = async (
    name,
    title,
    photo,
    clear_image,
    new_password = "",
  ) => {
    if (!user) return;
    setLoading(true);
    const form_data = new FormData();
    form_data.append("name", name);
    form_data.append("title", title);
    if (photo && photo instanceof File) {
      form_data.append("photo", photo);
    }
    form_data.append("clear_image", clear_image);
    form_data.append("new_password", new_password);

    const response = await request(
      "/api/auth/user",
      {
        method: "put",
        body: form_data,
      },
      false,
    );
    if (response.is_ok) {
      // reset error
      setError(false);
      // write jwt to cookie
      taco_cookie_ctx.set(AUTH_COOKIE_NAME, response.json.token);
      // and set userdata as state
      setUser(response.json.user);
      setLoading(false);
      return true;
    } else {
      setError(response.json.detail);
      setLoading(false);
      return false;
    }
  };

  const value = {
    user,
    error,
    is_loading,
    setLoading,
    signIn,
    signOut,
    tokenLogin,
    updateUser,
    updateUserPassword,
    getCreateUserLink,
    createUserTokenValidation,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used with AuthProvider");
  }
  return context;
};
