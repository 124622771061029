import { request, handleResponse } from "@/hooks/request";

type UpdateParams = {
  step_id: string;
  assignment_id: string;
  title: string;
};

const AssessmentApi = {
  getAssessmentUsers: (filter: any, abort_signal: AbortSignal) => {
    const search = new URLSearchParams(filter);
    return request(`/api/assessment/user?${search.toString()}`, {
      signal: abort_signal,
    }).then(handleResponse);
  },
  addAssessmentUser: (
    assessor_email: string,
    assignment_id: string,
    assessment_step_id: string,
    abort_signal: AbortSignal,
  ) => {
    return request("/api/assessment/user", {
      method: "post",
      signal: abort_signal,
      body: JSON.stringify({
        email: assessor_email,
        assignment_id,
        assessment_step_id,
      }),
    }).then(handleResponse);
  },
  removeAssessmentUser: (
    assessor_email: string,
    assignment_id: string,
    assessment_step_id: string,
    abort_signal: AbortSignal,
  ) => {
    return request("/api/assessment/user", {
      method: "delete",
      signal: abort_signal,
      body: JSON.stringify({
        email: assessor_email,
        assignment_id,
        assessment_step_id,
      }),
    }).then(handleResponse);
  },
  getCompetencies: (abort_signal: AbortSignal) => {
    return request("/api/competency", {
      signal: abort_signal,
    }).then(handleResponse);
  },
  getAssessmentSteps: (assignment_id: string, abort_signal: AbortSignal) => {
    return request(`/api/assessment/assignment/${assignment_id}/step`, {
      signal: abort_signal,
    }).then(handleResponse);
  },
  addAssessmentStep: (
    assignment_id: string,
    title: string,
    abort_signal: AbortSignal,
  ) => {
    return request("/api/assessment/step", {
      method: "post",
      signal: abort_signal,
      body: JSON.stringify({
        assignment_id,
        title,
      }),
    }).then(handleResponse);
  },
  updateAssessmentStep: (
    { step_id, assignment_id, title }: UpdateParams,
    abort_signal: AbortSignal,
  ) => {
    return request(`/api/assessment/step/${step_id}`, {
      method: "post",
      signal: abort_signal,
      body: JSON.stringify({
        title,
        assignment_id,
      }),
    }).then(handleResponse);
  },
  removeAssessmentStep: (step_id: string, abort_signal: AbortSignal) => {
    return request(`/api/assessment/step/${step_id}`, {
      method: "delete",
      signal: abort_signal,
    }).then(handleResponse);
  },
  changeAssessmentStepOrdering: (
    step_id: string,
    increase: boolean,
    abort_signal: AbortSignal,
  ) => {
    return request(
      `/api/assessment/step/${step_id}/ordering/${increase ? 1 : -1}`,
      {
        method: "put",
        signal: abort_signal,
      },
    ).then(handleResponse);
  },
  addCompetencyToAssessmentStep: (
    step_id: string,
    competency_id: string,
    abort_signal: AbortSignal,
  ) => {
    return request(
      `/api/assessment/step/${step_id}/competency/${competency_id}`,
      {
        method: "post",
        signal: abort_signal,
      },
    ).then(handleResponse);
  },
  removeCompetencyFromAssessmentStep: (
    step_id: string,
    competency_id: string,
    abort_signal: AbortSignal,
  ) => {
    return request(
      `/api/assessment/step/${step_id}/competency/${competency_id}`,
      {
        method: "delete",
        signal: abort_signal,
      },
    ).then(handleResponse);
  },
  getUserAssessment: (candidate_id: string, abort_signal: AbortSignal) => {
    return request(`/api/assessment/candidate/${candidate_id}`, {
      signal: abort_signal,
    }).then(handleResponse);
  },
  getAssessments: (assignment_id: string, abort_signal: AbortSignal) => {
    return request(`/api/assessment/assignment/${assignment_id}`, {
      signal: abort_signal,
    }).then(handleResponse);
  },
  getCandidateScorecards: (candidate_id: string, abort_signal: AbortSignal) => {
    return request(`/api/assessment/candidate/${candidate_id}/scorecard`, {
      signal: abort_signal,
    }).then(handleResponse);
  },
  getAssignmentScorecards: (
    assignment_id: string,
    abort_signal: AbortSignal,
  ) => {
    return request(`/api/assessment/assignment/${assignment_id}/scorecard`, {
      signal: abort_signal,
    }).then(handleResponse);
  },
  addAssessmentCandidate: (candidate_id: string, abort_signal: AbortSignal) => {
    return request(`/api/assessment/candidate/${candidate_id}`, {
      method: "post",
      signal: abort_signal,
    }).then(handleResponse);
  },
  removeAssessmentCandidate: (
    candidate_id: string,
    abort_signal: AbortSignal,
  ) => {
    return request(`/api/assessment/candidate/${candidate_id}`, {
      method: "delete",
      signal: abort_signal,
    }).then(handleResponse);
  },
  scoreCandidate: (
    candidate_id: string,
    competencies: string,
    abort_signal: AbortSignal,
  ) => {
    return request("/api/candidate/assessment", {
      method: "post",
      body: JSON.stringify({ candidate_id, competencies }),
      signal: abort_signal,
    }).then(handleResponse);
  },
};

export default AssessmentApi;
