import { Link, useRouteLoaderData } from "react-router";

import InfoBox from "@/components/candidate/infobox";
import Logs from "@/components/candidate/logs";
import Percentile from "@/components/percentile.tsx";
import UnknownUserImage from "@/components/common/UnknownUserImage.tsx";
import AssessmentButton from "@/components/assessment-button";
import { Badge } from "@/components/badge.tsx";
import { boxclass, cn } from "@/helpers.tsx";
import { useAuth } from "@/hooks/auth";
import type { AssignmentLoaderData } from "@/types/assignment";
import type { Candidate } from "@/types/candidate";
import type { Scorecard } from "@/types/scorecard";
import type { User } from "@/types/user";

interface CandidateInfoProps {
  candidate: Candidate;
  assessment?: Scorecard & {
    all_competencies_count: number;
    user_competencies_count: number;
  };
  full?: boolean;
}

export default function CandidateInfo({
  full,
  candidate,
  assessment,
}: CandidateInfoProps) {
  const { assignment } = useRouteLoaderData(
    "single-assignment",
  ) as AssignmentLoaderData;

  const has_evali_assessment = candidate.evali_results;

  const hide_logs =
    assignment.user_role === "assessor" &&
    !assignment.settings?.sharing_settings?.action_logs;

  return (
    <div className={cn("w-[60rem]", "mx-auto", "flex", "flex-col", "gap-4")}>
      <HeadingCard candidate={candidate} full={full} assessment={assessment} />
      {full && (
        <>
          {has_evali_assessment && <AsssessmentResults candidate={candidate} />}
          {!hide_logs && candidate.log.length > 0 && (
            <Logs candidate={candidate} />
          )}
        </>
      )}
    </div>
  );
}

const HeadingCard = ({
  candidate,
  full,
  assessment,
}: {
  candidate: Candidate;
  full?: boolean;
  assessment: Scorecard & {
    all_competencies_count: number;
    user_competencies_count: number;
  };
}) => {
  const { user }: { user: User } = useAuth();

  let should_assess = false;
  let should_edit = false;

  if (assessment && !Object.keys(assessment).includes("detail")) {
    const my_assessments = [];
    let assessments_done = 0;
    Object.entries(assessment.scores).forEach(([_, userscores]) => {
      userscores.forEach((userscore) => {
        assessments_done += 1;
        if (userscore.user.id === user.id) {
          my_assessments.push(userscore);
        }
      });
    });
    should_assess =
      my_assessments.length === 0 && assessment.user_competencies_count > 0;

    should_edit =
      (my_assessments.length > 0 &&
        my_assessments.length < assessment.user_competencies_count &&
        assessment.user_competencies_count > 0) ||
      my_assessments.length >= assessment.user_competencies_count;
  }
  return (
    <div
      className={cn(
        "mb-4 flex flex-col justify-between gap-2 md:flex-row md:gap-4",
        boxclass,
        "p-8",
      )}
    >
      <div className="flex flex-col gap-4 md:flex-row md:gap-8">
        <div className="w-32">
          {candidate.photo_url ? (
            <div
              className="aspect-square w-full rounded-full bg-center bg-cover"
              style={{
                backgroundImage: `url(${candidate.photo_url})`,
              }}
            />
          ) : (
            <UnknownUserImage style="w-32 h-32 rounded-full md:block hidden" />
          )}
        </div>
        <div className="flex justify-between">
          <div className="overflow-hidden">
            {!full && (
              <Link
                to={`/assignment/${candidate.assignment_id}/candidate/${candidate.id}`}
              >
                <h1 className="relative mb-2 whitespace-normal font-medium font-outfit text-2xl">
                  {`${candidate.name || ""}`}
                </h1>
              </Link>
            )}
            {full && (
              <h1 className="relative mb-2 whitespace-normal font-medium font-outfit text-2xl">
                {`${candidate.name || ""}`}
              </h1>
            )}
            <p className="text-gray-400">
              {candidate.title}
              {candidate.organization && <br />}
              {candidate.organization}
            </p>
            <div className="mt-4 flex items-center gap-4">
              {candidate.most_recent_action && (
                <Badge title="Most recent action">
                  {candidate.most_recent_action}
                </Badge>
              )}
              {should_edit && (
                <Link
                  to={`/assignment/${candidate.assignment_id}/assess/${candidate.id}`}
                >
                  <AssessmentButton>Edit assessment</AssessmentButton>
                </Link>
              )}
              {should_assess && (
                <Link
                  to={`/assignment/${candidate.assignment_id}/assess/${candidate.id}`}
                >
                  <AssessmentButton>Add assessment</AssessmentButton>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      <InfoBox candidate={candidate} />
    </div>
  );
};

const Section = ({ children }) => {
  return (
    <div className={cn(boxclass, "p-8")}>
      <div className="overflow-hidden">{children}</div>
    </div>
  );
};

const AsssessmentResults = ({ candidate }) => {
  return (
    <Section title={"Assessment"} start_open={true}>
      {candidate.evali_results && <EvaliResults candidate={candidate} />}
    </Section>
  );
};

const EvaliResults = ({ candidate }) => {
  const competency_scores = candidate.evali_results?.competency_scores || [];
  return (
    <div>
      <div className="p-8">
        <div className="mb-4 flex justify-between font-medium font-outfit text-3xl">
          <div>Match score:</div>
          <div>{candidate.evali_results.match_score} / 100</div>
        </div>
        {Object.keys(competency_scores).map((slug) => {
          return (
            <EvaliCompetencyResult key={slug} score={competency_scores[slug]} />
          );
        })}
        {candidate.evali_results?.reasoning_score && (
          <div className="mb-8">
            <div className="font-medium font-outfit text-2xl">
              Reasoning score:
            </div>
            <div className="mx-auto max-w-[40rem]">
              <Percentile
                value={candidate.evali_results?.reasoning_score[4]}
                always_open={true}
              />
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-center text-xs">
        Powered by{" "}
        <a
          className="ml-1 font-bold"
          rel="noopener noreferrer"
          href="https://evali.work/"
        >
          evali
        </a>
      </div>
    </div>
  );
};

const EvaliCompetencyResult = ({ score }) => {
  const name = score.name.split("-").reduce((prev, cur, i) => {
    return i ? `${prev} ${cur}` : cur.charAt(0).toUpperCase() + cur.slice(1);
  }, "");
  return (
    <div className="mb-4 flex">
      <div className="">
        <div className="font-medium font-outfit text-2xl">{name}</div>
        <p className="mb-2 font-bold">{score.description}</p>
        <p className="mb-2">{score.match_description}</p>
        {score.match_indicators?.length && (
          <div className="mb-2">
            <ul className="list-inside list-disc">
              {score.match_indicators.map((mi, i) => (
                <li key={i}>{mi}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="flex min-w-[5rem] justify-end font-medium text-xl md:min-w-[8rem]">
        {score.sten_score} / 10
      </div>
    </div>
  );
};
