import { Link, useLocation } from "react-router";
import clsx from "clsx";

export default function ProcessSteps({ assignment_id, steps }) {
  if (steps.length === 0) return null;
  return (
    <div className="mb-8 inline-flex divide-x divide-solid divide-slate-800 border border-slate-800">
      <NavLink
        to={`/assignment/${assignment_id}`}
        className="block px-4 py-1"
        is_main={true}
      >
        All candidates
      </NavLink>
      {steps.map((step) => {
        return (
          <NavLink
            key={step.id}
            to={`/assignment/${assignment_id}/process-step/${step.id}`}
            className="block px-4 py-1"
          >
            {step.name} ({step.candidate_ids.length})
          </NavLink>
        );
      })}
    </div>
  );
}

function NavLink(props) {
  const location = useLocation();

  const is_active = props.is_main
    ? location.pathname === props.to
    : location.pathname.startsWith(props.to);

  const { is_main, ...props_wo_main } = props;
  return (
    <Link
      {...props_wo_main}
      className={clsx(
        props.className,
        is_active && "font-medium text-future-purple",
      )}
    >
      {props.children}
    </Link>
  );
}
