import ReactDOM from "react-dom/client";
import { createBrowserRouter, type RouteObject } from "react-router";
import { RouterProvider } from "react-router/dom";

import "@/css/assemble.css";

import { AuthProvider } from "@/hooks/auth.tsx";
import { main_routes } from "@/routes/main.tsx";

const router = createBrowserRouter(main_routes as RouteObject[], {
  future: {
    v7_normalizeFormMethod: true,
    v7_relativeSplatPath: true,
    // v7_startTransition: true,
    v7_fetcherPersist: true,
    v7_partialHydration: true,
    v7_skipActionErrorRevalidation: true,
  },
});

const rootElement = document.getElementById("root") as HTMLElement;
if (!rootElement) throw new Error("Failed to find the root element");

ReactDOM.createRoot(rootElement).render(
  <AuthProvider>
    <RouterProvider router={router} future={{ v7_startTransition: true }} />
  </AuthProvider>,
);
