import { type LoaderFunctionArgs, useRouteLoaderData } from "react-router";

import AssignmentApi from "@/api/assignment-api";
import AssessmentApi from "@/api/assessment-api";
import CandidateList from "@/components/candidate-list";
import type { Competency } from "@/types/competency";
import type { CandidatesUnderAssessment } from "@/types/candidate";
import type { AssignmentScorecards } from "@/types/scorecard";
import type { AssignmentLoaderData } from "@/types/assignment";
import { usePageTitle } from "@/hooks/title";

export default function SingleAssignment() {
  const { assignment } = useRouteLoaderData(
    "single-assignment",
  ) as AssignmentLoaderData;
  usePageTitle(assignment.name);

  return (
    <div className="mx-auto max-w-[60rem]">
      <CandidateList
        candidates={assignment.candidates}
        assignment={assignment}
      />
    </div>
  );
}

export type LoaderData = {
  scorecards: AssignmentScorecards;
  competencies: Competency[];
  assessments: CandidatesUnderAssessment;
};

export async function loader({
  request,
  params: { assignment_id },
}: LoaderFunctionArgs): Promise<LoaderData> {
  if (!assignment_id) {
    throw new Error("Assignment ID is required");
  }
  const { data: scorecards } = await AssessmentApi.getAssignmentScorecards(
    assignment_id,
    request.signal,
  );
  const { data: assessments } = await AssessmentApi.getAssessments(
    assignment_id,
    request.signal,
  );
  const { data: competencies } = await AssignmentApi.getAssignmentCompetencies(
    assignment_id,
    request.signal,
  );
  return { scorecards, competencies, assessments };
}
